import React, { useCallback, useMemo, useState } from 'react';
import { CForm } from '@coreui/react-pro';
import {
  ExternalData,
  ICustomFields,
  IFormElement,
  IOption,
  ISmartFormElement,
  MediaMimeTypes,
  ObjectMap
} from '@types';
import { Col } from '../index';
import FormField from './form-field';
import { useSelector } from 'react-redux';
import storage from '@storage';

interface FormProps<T = {}> {
  fields?: ISmartFormElement[];
  data?: T;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  forwardedRef?: React.Ref<HTMLFormElement>;
  children?: React.ReactNode;
  toolbar?: JSX.Element;
  customFields?: ICustomFields<T>;
  loading?: boolean;
}

export default function Form<T = {}>(props: FormProps<T>) {
  const initial = props.data ?? {} as T;
  const roleGroup = useSelector(storage.userAccessRights.selectRoleGroup);
  const moduleOptions = useSelector(storage.userAccessRights.selectModuleOptions);
  const [changedData, setChangedData] = useState<T>(initial);

  const fields = useMemo(() => {
    let formElements: ISmartFormElement[] = [];
    if (props.fields) {
      for (const field of props.fields) {
        if (field.optionName && field.optionValue) {
          if (!moduleOptions.some(mo => mo.name && field.optionName?.includes(mo.name) && mo.value === field.optionValue)) {
            continue;
          }
        }
        formElements.push(field);
      }
    }

    return formElements?.map(field => mapFormField(field, changedData, initial))
      .filter(field =>
        (field.roleGroup === undefined ||
          field.roleGroup.length === 0 ||
          field.roleGroup.includes(roleGroup as any)));
  }, [changedData, props.fields, moduleOptions]);

  const onChange = useCallback((e: React.FormEvent<HTMLFormElement>) => {

    const element = e.target as HTMLInputElement;
    const data: ObjectMap = {
      ...initial,
      ...changedData,
      [element?.name]: element?.type === 'checkbox'
        ? element.checked
        : element?.type === 'file'
          ? element.files
          : element.value
    };

    if (element?.type === 'radio') {
      const radioItem: any = element.form?.elements.namedItem(element.name);
      if (radioItem && radioItem.length) {
        const radioNodeList = radioItem as RadioNodeList;
        for (let i = 0; i < radioNodeList.length; i++) {
          const node = radioNodeList.item(i) as HTMLInputElement;
          if (node && node.checked) {
            data[element?.name] = i;
          }
        }
      }
    }

    if (element.tagName === 'SELECT') {
      const select = e.target as HTMLSelectElement;
      const datatype = select.getAttribute('datatype');
      if (datatype === 'multi-select') {
        const values: string[] = [];
        for (let i = 0; i < select.selectedOptions.length; i++) {
          const value = select.selectedOptions[i]?.value;
          if (value) {
            values.push(value);
          }
        }
        data[element?.name] = values;
      } else {
        const jsonName = `${select.name}_option`;
        const json = (select.form?.elements.namedItem(jsonName) as any)?.value;
        try {
          data[`${element?.name}_option`] = JSON.parse(json);
        } catch (e) {
          //
        }
      }
    }

    const form = element.form;
    if (form && fields) {
      for (const field of fields) {
        if (field.value && field.name) {
          const value = field.value(data, initial);
          data[field.name] = value;
          const el: any = form.elements.namedItem(field.name);
          if (el) {
            el.value = value;
          }
        }
      }
    }
    setChangedData(data as T);
  }, [changedData, initial, fields]);

  const onSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    if (!e.currentTarget.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    e.preventDefault();
    await props.onSubmit?.call(null, e);
  }, [props.onSubmit]);

  return (
    <CForm
      ref={props.forwardedRef}
      onChange={onChange}
      onSubmit={onSubmit}
      className="row g-3"
    >
      {fields?.map((field, i) =>
        <Col col={field.col} key={i}>
          <FormField
            field={field}
            data={initial}
            changedData={changedData}
            customFields={props.customFields}
            loading={props.loading}
          />
        </Col>
      )}
      {props.children}
      <div className="mb-3" />
    </CForm>
  );
}

const mapFormField = (field: ISmartFormElement, data: any, initial: any): IFormElement => ({
  ...field,
  accept: getValue<MediaMimeTypes[]>('accept', field, data, initial),
  label: getValue<string>('label', field, data, initial),
  title: getValue<string>('title', field, data, initial),
  placeholder: getValue<string>('placeholder', field, data, initial),
  hidden: getValue<boolean>('hidden', field, data, initial),
  required: getValue<boolean>('required', field, data, initial),
  disabled: getValue<boolean>('disabled', field, data, initial),
  readOnly: getValue<boolean>('readOnly', field, data, initial),
  multiple: getValue<boolean>('multiple', field, data, initial),
  inline: getValue<boolean>('inline', field, data, initial),
  maxLength: getValue<number>('maxLength', field, data, initial),
  minLength: getValue<number>('minLength', field, data, initial),
  fields: field.fields?.map(x => mapFormField(x, data, initial)),
  options: getOptions(field, data, initial),
  externalData: getOptionsExternalData(field, data, initial),
  tooltip: getValue<string>('tooltip', field, data, initial)
});

const getValue = <T, >(propName: string, field: any, data: any, initial: any) => {
  const propValue = field[propName];
  const form = data ?? {};
  form[field.name] ??= field.defaultValue;
  return typeof propValue === 'function'
    ? propValue(form, initial) as T | undefined
    : propValue as T | undefined;
};

const getOptions = (field: any, data: any, initial: any) => {
  const value = getValue<IOption>('options', field, data, initial);
  return Array.isArray(value)
    ? value as IOption[]
    : undefined;
};

const getOptionsExternalData = <T, >(field: any, data: any, initial: any) => {
  const value = getValue<IOption>('options', field, data, initial);
  if (Array.isArray(value)) {
    return;
  }
  if (typeof value === 'object') {
    return value as ExternalData;
  }
};


