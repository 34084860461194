import React, { useEffect, useState } from 'react';
import glossary_en from './i18n/cui/en/glossary.json';
import glossary_ru from './i18n/cui/ru/glossary.json';
import { isEmpty } from 'lodash';
import { apiClients } from '@api';
import { Loading } from './app';
import { useI18n } from '@hooks';
import i18n from 'i18next';

// every 6 hours
const UPDATE_INTERVAL = 6 * 60 * 60 * 1000;

export function Localization(props: { children?: React.ReactNode }) {
  const [loading, setLoading] = useState(true);

  useI18n();

  // Функция для загрузки и обновления серверных переводов
  const updateServerTranslations = async () => {
    try {
      const response = await apiClients.default.get('/console/glossary/term/client');
      const serverTranslations: any[] = response.items ?? [];

      // Слияние серверных переводов с локальными
      const translations = {
        ...i18n.getResourceBundle('RU', 'translation'),
        ...serverTranslations.reduce(
          (result: any, item: any) => ({
            ...result,
            [item.key]: item.value.replace(/\\n/g, '\n').replace(/\\r/g, '\r')
          }),
          {}
        )
      };

      // Обновление ресурсов i18next
      i18n.addResourceBundle('RU', 'translation', translations, true, true);
    } catch (error) {
      console.error('Error fetching server translations:', error);
    }
  };

  useEffect(() => {
    // Загрузка серверных переводов для языка по умолчанию при инициализации
    updateServerTranslations().finally(() => setLoading(false));

    // Запуск загрузки серверных переводов каждые 6 часов
    const timer = setInterval(() => {
      updateServerTranslations().finally(() => setLoading(false));
    }, UPDATE_INTERVAL);

    return () => clearInterval(timer);
  }, []);

  if (loading) {
    return Loading;
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}