import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ContentTypes } from '@http/enums';
import { ObjectMap } from '@types';
import appContext from '../app-context';
import auth from './auth';


/**
 * Конфигурации HTTP-запроса
 */
export interface HttpRequestConfig extends AxiosRequestConfig {
}

/**
 * HTTP-ответ
 */
export interface HttpResponse<T = any> extends AxiosResponse<T> {
}

/**
 * HTTP-клиент
 */
export default interface HttpClient {
  /**
   * Отправить GET-запрос
   * @param url - URL
   * @param config - параметры
   */
  get: <T = any, R = HttpResponse<T>>(url: string, config?: HttpRequestConfig) => Promise<R>;

  /**
   * Отправить POST-запрос
   * @param url - URL
   * @param data - данные
   * @param config - параметры
   */
  post: <T = any, R = HttpResponse<T>>(url: string, data?: any, config?: HttpRequestConfig) => Promise<R>;

  /**
   * Отправить PUT-запрос
   * @param url - URL
   * @param data - данные
   * @param config - параметры
   */
  put: <T = any, R = HttpResponse<T>>(url: string, data?: any, config?: HttpRequestConfig) => Promise<R>;

  /**
   * Отправить DELETE-запрос
   * @param url - URL
   * @param config - параметры
   */
  delete: <T = any, R = HttpResponse<T>>(url: string, config?: HttpRequestConfig) => Promise<R>;

  /**
   * Отправить PATCH-запрос
   * @param url - URL
   * @param data - данные
   * @param config - параметры
   */
  patch: <T = any, R = HttpResponse<T>>(url: string, data?: any, config?: HttpRequestConfig) => Promise<R>;
}

/**
 * Применить конфигурации запроса к входящим конфигурациям
 * @param config - входящие конфигурации запроса
 */
function applyConfig(config?: HttpRequestConfig) {
  const cfg: HttpRequestConfig = { ...config };
  cfg.headers = { ...cfg.headers, 'Authorization': `Bearer ${auth.access_token}` };
  return cfg;
}

/**
 * Нормализация объекта
 * @param obj - объект
 */
export const normalizeQueryParams = <T = object>(obj: T) => {
  const params: ObjectMap = {};
  if (obj) {
    for (const [key, value] of Object.entries(obj)) {
      if (value === undefined || value === '') {
        continue;
      }
      if (Array.isArray(value) || typeof value != 'object') {
        params[key] = value;
        continue;
      }

      for (const [k, v] of Object.entries(value)) {
        if (v === undefined) {
          continue;
        }
        if (Array.isArray(v) && v.length === 0) {
          continue;
        }
        if (Array.isArray(v) || typeof v != 'object') {
          params[`${key}.${k}`] = v;
        }
      }
    }
  }
  return params;
};

/**
 * Сериализатор параметров
 * @param params - параметры
 */
export const paramsSerializer = (params: any) => {
  const searchParams = new URLSearchParams();
  const normalized = normalizeQueryParams(params);

  for (const [key, value] of Object.entries(normalized)){
    if (Array.isArray(value)) {
      value.forEach(v => searchParams.append(key, v))
    } else {
      searchParams.append(key, value)
    }
  }

  return searchParams.toString();
};

/**
 * Создать HTTP-клиент
 * @param baseURL - базовый URL
 * @param contentType - типы контента
 */
export function createHttpClient(
  baseURL: string,
  contentType: ContentTypes = ContentTypes.ApplicationJSON): HttpClient {
  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': contentType,
      'x-os-name': appContext.os.name ?? '',
      'x-os-version': appContext.os.version ?? '',
      'x-client-id': appContext.client.id ?? '',
      'x-client-name': appContext.client.name ?? '',
      'x-client-version': appContext.client.version ?? '',
      'x-device-id': appContext.device.id ?? '',
      'x-device-type': appContext.device.type ?? '',
      'x-device-model': appContext.device.model ?? '',
      'x-device-manufacturer': appContext.device.manufacturer ?? '',
      'x-language': 'ru'
    },
    paramsSerializer: paramsSerializer,
    validateStatus: _ => true
  });

  const execute = async <T = any, R = HttpResponse<T>>(send: () => Promise<R>) => {
    const response: any = await send();
    if (response.status !== 401) {
      return response;
    }

    const refreshResponse = await auth.refresh();
    if (refreshResponse.error) {
      await auth.logout();
      return response;
    }

    return await send();
  };

  return {
    get: <T = any, R = HttpResponse<T>>(url: string, config?: HttpRequestConfig) => execute<T, R>(() =>
      axiosInstance.get<T, R>(url, applyConfig(config))),

    post: <T = any, R = HttpResponse<T>>(url: string, data?: any, config?: HttpRequestConfig) => execute<T, R>(() =>
      axiosInstance.post<T, R>(url, data, applyConfig(config))),

    put: <T = any, R = HttpResponse<T>>(url: string, data?: any, config?: HttpRequestConfig) => execute<T, R>(() =>
      axiosInstance.put<T, R>(url, data, applyConfig(config))),

    delete: <T = any, R = HttpResponse<T>>(url: string, config?: HttpRequestConfig) => execute<T, R>(() =>
      axiosInstance.delete<T, R>(url, applyConfig(config))),

    patch: <T = any, R = HttpResponse<T>>(url: string, data?: any, config?: HttpRequestConfig) => execute<T, R>(() =>
      axiosInstance.patch<T, R>(url, data, applyConfig(config)))
  };
}


