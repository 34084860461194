import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CButton } from '@coreui/react-pro';
import { ControlProps } from '@smart-table/types';
import { usePropertyValue } from '@hooks';
import { ConfirmationModal } from '@component';
import { ApiResponse, DataApiResponse } from '@api/types';
import { IDisplayObject, ObjectMap } from '@types';
import { apiClients } from '@api';
import storage from '@storage';
import Svg from '@svg';

interface DeleteButtonProps extends ControlProps {
  preflying?: boolean;
}

type MessageItem = string | IDisplayObject;

const PARAM_REGEX = /\{\{[0-9a-zA-Z]+}}/g;

const DEFAULT_CONTENT = 'Вы действительно хотите удалить запись?';

function Button(props: DeleteButtonProps) {
  const tableName = props.tableName ?? '';
  const item = props.item ?? {};
  const disabled = usePropertyValue(item, props.disabled);
  const hidden = usePropertyValue(item, props.hidden);

  const [visibleSaveModal, setVisibleSaveModal] = useState(false);
  const [deleteable, setDeleteable] = useState<boolean>();
  const [sending, setSending] = useState(false);

  const [messageItems, setMessageItems] = useState<MessageItem[]>([DEFAULT_CONTENT]);

  const dispatch = useDispatch();

  const onClick = useCallback(async () => {
    if (!props.preflying) {
      setMessageItems([DEFAULT_CONTENT]);
      setVisibleSaveModal(true);
      setDeleteable(true);
      return;
    }

    const response = await apiClients.default.delete<DataApiResponse>(`${tableName}/${item.id}/preflying`);
    if (response.errorCode) {
      dispatch(storage.toast.setError(response.errorMsg!));
      return;
    }

    if (response.data?.paramsCount > 0) {
      const items = getMessageItems(response.data?.message, response.data?.params);
      setMessageItems(items);
      setVisibleSaveModal(true);
      setDeleteable(response.data?.deletable);
      return;
    }

    setMessageItems([DEFAULT_CONTENT]);
    setVisibleSaveModal(true);
  }, [props.preflying, tableName, item.id]);

  const onConfirm = useCallback(async () => {
    try {
      setSending(true);
      if (props.onClick) {
        props.onClick(item);
      } else {
        const response = await apiClients.default.delete<ApiResponse>(`${tableName}/${item.id}`);
        if (response.errorCode) {
          dispatch(storage.toast.setError(response.errorMsg!));
        } else {
          dispatch(storage.smartTable.setLoading(tableName, true));
        }
      }
    } finally {
      setVisibleSaveModal(false);
      setSending(false);
    }
  }, [props.onClick, item, tableName, item.id]);

  return (
    <>
      <CButton
        color={'danger'} size='sm'
        className='me-1 p-1 justify-content-center d-flex flex-column'
        onClick={onClick}
        disabled={disabled}
        hidden={hidden}
      >
        <Svg.Icon.DeleteButton fill='white' />
      </CButton>
      <ConfirmationModal
        data={item}
        visible={visibleSaveModal}
        title={'Удаление'}
        content={<>{messageItems.map((item, i) => (
          <span key={i}>
            {typeof item === 'string'
              ? item === '\n'
                ? <br />
                : item
              : typeof item === 'object'
                ? (
                  <i style={{ color: '#39f' }}>
                    {item.name}
                  </i>
                )
                : ''}
          </span>
        ))}</>}
        confirming={sending}
        confirmingText={'Удаление...'}
        confirmText={'Удалить'}
        confirmable={deleteable}
        unmountOnClose={true}
        onConfirm={onConfirm}
        onCancel={() => setVisibleSaveModal(false)}
      />
    </>
  );
}

type DeleteButton = typeof Button & { props: (props: DeleteButtonProps) => (props: ControlProps) => JSX.Element };
const DeleteButton: DeleteButton = Button as DeleteButton;
DeleteButton.props = (props: DeleteButtonProps) => (defProps: ControlProps) => <Button {...defProps} {...props} />;
export default DeleteButton;

const getMessageItems = (source?: string, params?: ObjectMap) => {
  source ??= '';
  params ??= {};
  let text = source;
  let content: MessageItem[] = [];
  const matches = source.match(PARAM_REGEX) ?? [];

  for (const match of matches) {
    const key = match.substring(2, match.length - 2);
    const param = params[key];

    if (Array.isArray(param) && typeof param[0] === 'object') {
      const prevText = text.substring(0, text.indexOf(matches[0])).split('\n');
      const prevTextLastIndex = prevText.length - 1;
      for (let i = 0; i < prevText.length; i++) {
        content.push(prevText[i]);
        if (i != prevTextLastIndex) {
          content.push('\n');
        }
      }

      const values = param as IDisplayObject[];
      const valuesLastIndex = values.length - 1;
      for (let i = 0; i < values.length; i++) {
        content.push(values[i]);
        if (i != valuesLastIndex) {
          content.push(', ');
        }
      }

      text = text.substring(text.indexOf(matches[0]) + matches[0].length);
    }
  }

  const prevText = text.split('\n');
  const prevTextLastIndex = prevText.length - 1;
  for (let i = 0; i < prevText.length; i++) {
    content.push(prevText[i]);
    if (i != prevTextLastIndex) {
      content.push('\n');
    }
  }

  return content;
};