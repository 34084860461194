import React, { useEffect, useMemo } from 'react';
import { FormMultiSelect } from '@component';
import { IOption, ObjectMap } from '@types';
import { useDispatch, useSelector } from 'react-redux';
import storage from '@storage';

interface SmartFormMultiSelectProps {
  name?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  hidden?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: string | string[];
  valueType?: 'string';
  emptyOption?: boolean;
  loading?: boolean;
  actionUrl: string;
  params?: ObjectMap;
  filterOptions?: (option: IOption) => boolean | undefined;
  virtualScroller?: boolean;
  visibleItems?: number;
  selectAll?: boolean;
  valueKey?: string;
  disabledIfEmpty?: boolean;
}

export default function SmartFormMultiSelect(props: SmartFormMultiSelectProps) {
  const dispatch = useDispatch();
  const dropdownName = props.name ?? props.actionUrl;
  const loading = useSelector(storage.dropdown.selectLoading(dropdownName));
  const items = useSelector(storage.dropdown.selectItems(dropdownName));

  useEffect(() => {
    if (props.loading !== false) {
      dispatch(storage.dropdown.setProps(
        dropdownName,
        props.actionUrl,
        props.params,
        props.loading,
        props.emptyOption));
    }
  }, [
    dropdownName,
    props.actionUrl,
    props.params,
    props.loading,
    props.emptyOption
  ]);

  useEffect(() => {
    if (loading) {
      dispatch(storage.dropdown.loadData(dropdownName));
    }
  }, [loading]);

  const valueKey = useMemo(() => props.valueKey ?? 'id', [props.valueKey]);

  const defaultValue = useMemo(() =>
      typeof props.defaultValue === 'string'
        ? [props.defaultValue]
        : props.defaultValue?.map(value => {
        return typeof value === 'object'
          ? (value[valueKey] as any)?.toString()
          : value.toString();
      }) ?? [],
    [props.defaultValue]);

  return (
    <FormMultiSelect
      name={props.name}
      label={props.label}
      description={props.description}
      placeholder={props.placeholder}
      required={props.required}
      readOnly={props.readOnly}
      disabled={props.disabled || (props.disabledIfEmpty && (!items || items?.length === 0))}
      hidden={props.hidden}
      defaultValue={defaultValue}
      options={items}
      virtualScroller={props.virtualScroller}
      filterOptions={props.filterOptions}
      visibleItems={props.visibleItems}
      selectAll={props.selectAll}
    />
  );
}