import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { CBadge, CNavGroup, CNavItem, CNavTitle } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { INavItem } from '@types';
import { getIcon } from '@utils/icons';

interface AppSidebarNavProps {
  items?: INavItem[];
}

export default function AppSidebarNav({ items }: AppSidebarNavProps) {
  return (
    <React.Fragment>
      {items?.map((item, i) => (
        <AppNavItem item={item} key={i} idx={i} />
      ))}
    </React.Fragment>
  );
}

interface AppNavItemProps {
  item: INavItem,
  idx?: number
}

function AppNavItem(props: AppNavItemProps) {
  const location = useLocation();
  const visible = useMemo(() =>
      !!props.item.to && location.pathname.startsWith(props.item.to),
    [location.pathname, props.item.to]);

  switch (props.item.type) {
    case 'title':
    case undefined:
      return (
        <>
          <CNavTitle title={props.item.name}>
            <AppNavLink item={props.item} />
          </CNavTitle>
          {props.item.items?.map((item, i) =>
            <AppNavItem item={item} key={i} idx={i} />
          )}
        </>
      );

    case 'item':
      return (
        <CNavItem
          title={props.item.name}
          to={props.item.to}
          disabled={props.item.disabled}
          component={NavLink}
        >
          <AppNavLink item={props.item} />
        </CNavItem>
      );

    case 'group':
      return (
        <CNavGroup
          toggler={<AppNavLink item={props.item} />}
          visible={visible}
          idx={props.idx?.toString()}
          key={props.idx}
        >
          {props.item.items?.map((item, i) =>
            <AppNavItem item={item} key={i} idx={i} />
          )}
        </CNavGroup>
      );

    default:
      return <>
      </>;
  }
}

interface AppNavLinkProps {
  item: INavItem;
}

const AppNavLink = (props: AppNavLinkProps) => {
  const icon = useMemo(() => getIcon(props.item.icon), [props.item.icon]);
  return (
    <>
      {icon && <CIcon icon={icon} customClassName='nav-icon' />}
      {props.item.name && props.item.name}
    </>
  );
};
