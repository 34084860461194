import React from 'react';
import { CFormInput } from '@coreui/react-pro';
import { FormLabel } from '@component';

interface FormInputProps {
  type?: string;
  name?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  defaultValue?: string | number | readonly string[];
  value?: string | number | string[];
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export default function FormInput(props: FormInputProps) {
  return (
    <>
      {props.label && (
        <FormLabel description={props.description}>
          {props.label}
        </FormLabel>
      )}
      <CFormInput
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        value={props.value}
        required={props.required}
        readOnly={props.readOnly}
        disabled={props.disabled}
        hidden={props.hidden}
        maxLength={props.maxLength}
        min={props.min}
        max={props.max}
      />
    </>
  );
}