import React, { useCallback } from 'react';
import { CellProps } from '@smart-table/types';
import { useDispatch } from 'react-redux';
import storage from '@storage';

export default function CopyableCell(props: CellProps) {
  const dispatch = useDispatch();
  const value = props.item[props.columnKey];

  const onClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(value);
      dispatch(storage.popup.setText('Скопировано'))
    } catch (e) {
      console.log(e)
    }
  }, [value, dispatch]);

  return (
    <>
      <td onClick={onClick} className='cursor-pointer' >
        {value}
      </td>
    </>
  );
}