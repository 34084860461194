import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CButton, CFormInput, CFormSelect } from '@coreui/react-pro';
import { ToolbarButtonProps } from '@toolbar';
import { ConfirmationModal, SmartFormSelect } from '@component';
import { useDispatch, useSelector } from 'react-redux';
import storage from '@storage';
import { apiClients } from '@api';
import { ApiResponse } from '@api/types';
import { ContentTypes } from '@http/enums';
import { IOption } from '@types';

interface ImportButtonProps extends ToolbarButtonProps {
  importUrl: string;
  importTitle?: string;
  format?: FileFormat[];
}

type FileFormat = 'csv_utf8' | 'csv_windows1251' | 'xlsx';

const fileFormatOptions: IOption[] = [
  {
    label: 'CSV (кодировка UTF-8)(*.csv)',
    value: 'csv_utf8'
  },
  {
    label: 'CSV (кодировка Windows-1251)(*.csv)',
    value: 'csv_windows1251'
  },
  {
    label: 'Excel (*.xlsx)',
    value: 'xlsx'
  }
];

function Button(props: ImportButtonProps) {
  const dispatch = useDispatch();

  const fileRef = useRef<HTMLInputElement>(null);
  const isACOU = useSelector(storage.userAccessRights.selectIsACOU);
  const [organizationId, setOrganizationId] = useState<number>();
  const [format, setFormat] = useState(props.format && props.format[0]);
  const [visible, setVisible] = useState(false);
  const [submiting, setSubmiting] = useState(false);

  const [organizationInvalid, setOrganizationInvalid] = useState(false);
  const [formatInvalid, setFormatInvalid] = useState(false);
  const [fileInvalid, setFileInvalid] = useState(false);

  const validate = useCallback(() => {
    const organizationInvalid = !isACOU && !organizationId;
    setOrganizationInvalid(organizationInvalid);

    const formatInvalid = !format;
    setFormatInvalid(formatInvalid);

    const fileInvalid = !fileRef.current?.value;
    setFileInvalid(fileInvalid);

    return !organizationInvalid && !formatInvalid && !fileInvalid;
  }, [isACOU, organizationId, format, fileRef.current?.value]);

  const onConfirm = useCallback(async () => {
    if (!validate()) {
      return;
    }
    const formData = new FormData();
    if (organizationId) {
      formData.append('organizationId', organizationId.toString());
    }
    if (format) {
      formData.append('format', format);
    }
    const file = fileRef.current?.files?.item(0);
    if (file) {
      formData.append('file', file, file.name);
    }
    setSubmiting(true);
    try {
      const headers = { 'Content-Type': ContentTypes.MultipartFormData };
      const response = await apiClients.default.post<ApiResponse>('employee/import', formData, { headers });
      if (response.errorCode) {
        dispatch(storage.toast.setError(response.errorMsg ?? 'Системная ошибка'));
        return;
      }
      setVisible(false);
      props.onClick?.call(null);
    } finally {
      setSubmiting(false);
    }
  }, [validate, isACOU, organizationId, format, fileRef.current?.files?.item(0)]);

  const accept = useMemo(() =>
      format === 'xlsx'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        : 'text/csv',
    [format]);

  const options = useMemo(() =>
      props.format && props.format.length > 0
        ? props.format
          .map(x => fileFormatOptions.find(z => z.value == x))
          .filter(x => !!x) as IOption[]
        : fileFormatOptions,
    [props.format, fileFormatOptions]);

  return (
    <>
      <CButton
        color={props.color ?? 'warning'}
        onClick={() => {
          setVisible(true);
          setSubmiting(false);
          setOrganizationId(undefined)
          setOrganizationInvalid(false);
          setFormatInvalid(false);
          setFileInvalid(false);
          if (fileRef.current) {
            fileRef.current.value = '';
          }
        }}
      >
        {props.text ?? 'Импортировать'}
      </CButton>
      <ConfirmationModal
        visible={visible}
        title={props.importTitle ?? 'Параметры импорта'}
        confirming={submiting}
        confirmingText={'Импортирование'}
        confirmText={'Импортировать'}
        confirmColor={props.color ?? 'warning'}
        onConfirm={onConfirm}
        onCancel={() => setVisible(false)}
        unmountOnClose
        content={(
          <div>
            {!isACOU && (
              <SmartFormSelect
                className='mb-2'
                actionUrl={'organization/dropdown'}
                disabled={submiting}
                emptyOption
                required
                invalid={organizationInvalid}
                feedbackInvalid={organizationInvalid && 'Выберите организацию из списка'}
                label='Организация *'
                onChange={(event) => {
                  const value = Number.parseInt(event.currentTarget.value);
                  const id = value > 0 ? value : undefined;
                  setOrganizationId(id);
                  setOrganizationInvalid(!id);
                }}
              />
            )}
            <CFormSelect
              className='mb-2'
              label='Формат файла *'
              disabled={submiting}
              required
              invalid={formatInvalid}
              feedbackInvalid={formatInvalid && 'Выберите формат импортируемого файла'}
              onChange={event => {
                if (fileRef.current) {
                  fileRef.current.value = '';
                }
                setFormat(event.currentTarget.value as FileFormat);
                setFormatInvalid(!event.currentTarget.value)
              }}
              options={options}
            />
            <CFormInput
              label='Файл импорта *'
              type='file'
              accept={accept}
              disabled={submiting}
              ref={fileRef}
              required
              invalid={fileInvalid}
              feedbackInvalid={fileInvalid && 'Выберите файл для импорта'}
              onChange={() => setFileInvalid(!fileRef.current?.value)}
            />
          </div>
        )}
      />
    </>
  );
}

type ImportButton = typeof Button & { props: (props: ImportButtonProps) => () => JSX.Element };
const ImportButton: ImportButton = Button as ImportButton;
ImportButton.props = (props: ImportButtonProps) => () => <Button {...props} />;
export default ImportButton;