import React, { useMemo } from 'react';

interface TrancateTextProps {
  lines?: number;
  children?: React.ReactNode;
}

/**
 * Усеченный текст
 * @param props
 * @constructor
 */
export default function TrancatedText(props: TrancateTextProps) {
  const style = useMemo(() => ({ '--lines': props.lines ?? 5 } as any), [props.lines]);
  return (
    <div style={{ wordBreak: 'break-word' }}>
      <div style={style} className='trancate-text-cell'>
        {props.children}
      </div>
    </div>
  );
}