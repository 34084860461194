import React, { useCallback, useState } from 'react';
import { CDatePicker } from '@coreui/react-pro';
import { Nullable } from '@types';
import { FormLabel } from '@component';

interface DatePickerProps {
  name?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onDateChange?: (date: Nullable<Date>, formattedDate?: string) => void;
  timepicker?: boolean
}

export default function DatePicker(props: DatePickerProps) {
  const [date, setDate] = useState(props.defaultValue);

  const onDateChange = useCallback((date: Nullable<Date>, formattedDate?: string) => {
    setDate(date?.toISOString());
    props.onDateChange?.call(null, date, formattedDate);
  }, [props.onDateChange]);

  return (
    <>
      {props.label && (
        <FormLabel description={props.description}>
          {props.label}
        </FormLabel>
      )}
      <CDatePicker
        date={date}
        locale='ru-RU'
        placeholder={props.placeholder ?? 'Выберите дату'}
        onDateChange={onDateChange}
        inputReadOnly={props.readOnly}
        disabled={props.disabled}
        hidden={props.hidden}
        timepicker={props.timepicker}
        cancelButton={'Отмена'}
        todayButton={'Сегодня'}
        confirmButton={'ОК'}
      />
      <input
        hidden
        name={props.name}
        value={date ?? ''}
        onChange={() => {}}
      />
    </>
  );
}