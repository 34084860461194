import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CDateRangePicker } from '@coreui/react-pro';
import { ColumnFilterProps } from '@smart-table/types';
import { IDateTimeRange } from '@types';
import storage from '@storage';

/**
 * Компонет фильтра диапазона дат
 */
export default function DateRangeFilter(props: ColumnFilterProps) {

  const dateRange = useSelector(storage.smartTable.selectFilter<IDateTimeRange>(props.tableName, props.columnKey));
  const [start, setStart] = useState(dateRange?.start);
  const [end, setEnd] = useState(dateRange?.end);

  useEffect(() => {
    if (start != dateRange?.start || end != dateRange?.end) {
      if (start || end) {
        props.onChange({ start, end });
      } else {
        props.onChange(undefined);
      }
    }
  }, [start, end, props.tableName, props.columnKey, dateRange]);

  return (
    <CDateRangePicker
      size='sm'
      placeholder={['Дата начала', 'Дата окончания']}
      startDate={start}
      endDate={end}
      onStartDateChange={(date) => setStart(date?.toISOString())}
      onEndDateChange={(date) => setEnd(date?.toISOString())}
    />
  );
}