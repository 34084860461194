import React, { useMemo } from 'react';
import { CellProps } from '@smart-table/types';
import { TrancatedText } from '@component';

export default function LocalizedString(props: CellProps & { lines?: number }) {
  const value = props.item[props.columnKey];

  return (
    <td>
      <TrancatedText>
        {value && (value.ru ?? value.en)}
      </TrancatedText>
    </td>
  );
}